<template>
  <div
    class="mt-0.5"
    :style="{ paddingLeft: depth * 20 + 'px' }"
  >
    <label
      :class="[
        'flex items-center',
        isTerpenes
          ? 'cursor-pointer font-normal text-sm py-1 px-2 rounded-full inline-flex font-alt border border-teal text-blue terpene-checkbox'
          : ''
      ]"
      @click="isTerpenes && changeHandler($event)"
    >
      <input
        v-if="!isTerpenes"
        data-lpignore="true"
        type="checkbox"
        class="shop-checkbox"
        :checked="selected"
        @change="changeHandler"
      />
      <span
        :class="[
          'ml-2',
          isTerpenes ? 'checkbox-terpenes' : 'font-alt font-bold text-black text-sm  py-1 flex items-center before:mr-2'
        ]"
      >
        {{ item.label }}
      </span>
    </label>
  </div>
</template>

<script>
  export default {
    name: 'ShopPageCheckbox',
    props: {
      filtersCleared: {
        type: Boolean,
        default: false
      },
      item: {
        type: Object,
        required: true
      },
      selected: {
        type: Boolean,
        default: false
      },
      depth: {
        type: Number,
        default: 0
      },
      isTerpenes: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        internalSelected: this.selected
      }
    },
    watch: {
      selected(newValue) {
        this.internalSelected = newValue
      },
      filtersCleared(newValue) {
        if (newValue) {
          this.updateChecked(false)
        }
      }
    },
    methods: {
      changeHandler(event) {
        if (this.isTerpenes) {
          event.preventDefault()
          this.$emit('checkbox', { item: this.item, checked: !this.selected })
        } else {
          this.$emit('checkbox', { item: this.item, checked: event.target.checked })
        }
      },
      updateChecked(value) {
        this.internalSelected = value
        if (this.$refs.checkbox) {
          this.$refs.checkbox.checked = value
        }
      }
    }
  }
</script>

<style scoped>
  .shop-checkbox {
    position: absolute;
    z-index: -1;
    opacity: 0;
    border: 1px solid #7c93a5;
  }

  .shop-checkbox + *::before {
    content: '';
    display: inline-block;
    background: #fff;
    border-radius: 3px;
    border: 1px solid #25c9d0;
    height: 20px;
    width: 20px;
    overflow: auto;
  }

  .shop-checkbox:checked + *::before {
    background-color: #3fb9c5;
    border: 3px solid #fff;
    outline: 1px solid #7c93a5;
  }

  .terpene-checkbox {
    transition: all 0.3s ease;
  }

  .selected .terpene-checkbox {
    background-color: #3fb9c5; /* Tailwind's green-400 */
    color: white;
  }

  .checkbox-terpenes {
    position: relative;
    display: inline-block;
    width: 100%;
    height: 100%;
  }
</style>
